<template>
  <div class="redEnvelopesBg">
    <div v-show="!show">
      <img src="../../assets/redEnvelopes/bgz.png" alt="" />
      <div class="redEnvelopesBoxError" v-show="loading">
        <img
          src="../../assets/loading.gif"
          alt=""
          style="width: 140%; margin-left: -20%"
        />
        <!-- <van-loading size="30px" type="spinner" vertical color="#823D07"
          >发放中...</van-loading
        > -->
      </div>
      <div v-show="!loading">
        <div class="redEnvelopesBox" v-if="redStates">
          <div class="congratulations">恭喜发财，大吉大利！</div>
          <p class="amount">
            <span>{{ money }}</span> 元
          </p>
          <div class="amountTips">已存入零钱，可直接消费</div>
        </div>
        <div class="redEnvelopesBoxError" v-else>
          <div class="congratulations">{{ errorText }}</div>
        </div>
      </div>
      <div class="bottomLogo">
        <img src="../../assets/redEnvelopes/bottom.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { receiveRedPacket } from "../../api/index.js";
import { journal } from "../../api/index.js";
import { wxshareNO } from "../../utils/date.js";
import { Toast } from "vant";
export default {
  name: "authorize",
  data() {
    return {
      tips: "恭喜您获得红包一个</br>点击“开”打开红包",
      openRed: true,
      money: "",
      redStates: false,
      isOpen: true,
      openRedRepeat: false,
      openRedSuccess: false,
      errorText: "",
      loading: true,
    };
  },
  methods: {
    openAxjx() {
      var that = this;
      if (this.isOpen) {
        this.isOpen = false;
        receiveRedPacket({
          prizeId: this.$route.query.prizeId,
          drawLogId: this.$route.query.drawLogId,
        }).then(
          (res) => {
            this.loading = false;
            if (res.data.status == 200) {
              this.openRed = false;
              this.openRedSuccess = true;
              this.redStates = true;
              this.money = res.data.data.unit_money;
            } else if (res.data.status == 400001) {
        
              this.redStates = false;
              this.errorText = "您的红包已发放";
            } else {
              this.redStates = false;
              this.errorText = res.data.msg;
            }
            this.isOpen = true;
          },
          function () {
            that.isOpen = true;
          }
        );
      }
    },
    returnBtn() {
      this.$router.go(-1);
    },
  },
  mounted: function () {
    console.log(this.$route.query.prizeId);
    var maidian = {
      userVisit: "17",
    };
    journal(maidian);
    wxshareNO();
    this.openAxjx();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.errorText {
  width: 430px;
  margin: 0 auto;
  font-size: 34px;
  text-align: left;
}
/* body {
  background: #c5000f;
} */
.redEnvelopesBg {
  background: #c5000f;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 40px;
}
.redEnvelopesBg img {
  display: block;
  width: 100%;
}

.redEnvelopesBox {
  position: absolute;
  top: 722px;
  left: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 750px;
  text-align: center;
}

.redEnvelopesTips {
  width: 692px;
  padding: 20px 0;
  background: rgba(136, 33, 10, 0.3);
  text-align: center;
  line-height: 48px;
  font-size: 34px;
  color: #fcee98;
  margin: 0 auto;
  border-radius: 5px;
}

.redEnvelopesBorder {
  position: relative;
  margin-top: 20px;
}

.redEnvelopesresult {
  width: 750px;
  margin-top: 20px;
}

.edEnvelopesMoney {
  position: absolute;
  top: 460px;
  left: 0;
  font-size: 40px;
  width: 100%;
  text-align: center;
  color: rgb(240, 218, 21);
}
.errorTips {
  width: 750px;
  margin-top: 100px;
}
.errorTips .edEnvelopesMoney {
  top: 420px;
}
.redEnvelopesOpen {
  position: absolute;
  top: 220px;
  width: 124px;
  left: 313px;
  animation: move 0.8s 0s infinite;
  -webkit-animation: move 0.8s 0s infinite;
  transform-origin: bottom;
  -webkit-transform-origin: bottom;
}

.edEnvelopesMoney span {
  font-size: 80px;
  color: red;
  text-shadow: #fff 2px 0 0, #fff 0 2px 0, #fff -2px 0 0, #fff 0 -2px 0;
}
.whiteColor {
  color: #fff;
}
.callBack {
  width: 417px;
  margin: 20px auto 0;
}

@keyframes move {
  0% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}
.congratulations {
  color: #caa86c;
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 60px;
}
.amount {
  color: #caa86c;
  font-size: 50px;
  margin-bottom: 10px;
}
.amount span {
  font-size: 100px;
}
.amountTips {
  font-size: 20px;
  color: #999999;
}
.bottomLogo {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 447px;
}
.redEnvelopesBoxError {
  position: absolute;
  top: 700px;
  left: 50%;
  display: flex;
  align-items: center;
  transform: translateX(-50%);
  height: 360px;
}
</style>
